import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Seo from '../seo'
import { Layout, Center } from '../layout'

import { Location } from '../../interfaces/gatsby'
import { SiteMetadata } from '../../interfaces/siteMetadata'
import { allMarkdownRemark } from '../../interfaces/markdownRemark'

interface Props {
  en: boolean
  siteMetadata: SiteMetadata
  location: Location
  data: allMarkdownRemark
}

export const Categories = ({ en, siteMetadata, location, data }: Props) => {
  const categories = data.group.map((g: any) => g.fieldValue)
  const path = en ? '/en' : ''

  return (
    <Layout en={en} location={location} siteMetadata={siteMetadata}>
      <Seo lang={en ? 'en' : 'ja'} />
      <Center>
        <CategoriesDiv>
          <ul>
            {categories.map((c: string) =>
              en && c === 'english' ? null : (
                <li key={c}>
                  <Link to={`${path}/category/${c}`}>&#9733; {c.charAt(0).toUpperCase() + c.slice(1)}</Link>
                </li>
              )
            )}
          </ul>
        </CategoriesDiv>
      </Center>
    </Layout>
  )
}

const CategoriesDiv = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  li {
    margin: 100px 0;

    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.05rem;

    a {
      color: ${({ theme }) => theme.gold};
    }
  }
`
